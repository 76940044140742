.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  width: 24px;
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

:root {
  --brandcolor: #2290c7;
  --background: #212121;
  --background-deemphasized: #111;
  --background-highlight: #000;
  --text: #e0e0e0;
  --accent-light: #2290c7;
  --accent-dark: #0d364b;
  --accent-scheme: var(--accent-dark);
  --header-background: #000;
  --header-text: #e0e0e0;
  --menu-background: linear-gradient(to right bottom, var(--accent-light), var(--accent-dark));
  --menu-text: #eee;
}

@media (prefers-color-scheme: light) {
  :root {
    --brandcolor: #2290c7;
    --background: #f5f5f5;
    --background-deemphasized: #eee;
    --background-highlight: #fff;
    --text: #424242;
    --accent-light: #91c8e3;
    --accent-dark: #2290c7;
    --accent-scheme: var(--accent-light);
    --header-background: #424242;
    --header-text: #fff;
    --menu-background: linear-gradient(to right bottom, var(--accent-light), var(--accent-dark));
    --menu-text: #fff;
  }
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

body {
  color: var(--text);
  background-color: var(--background);
  flex-direction: column;
  min-height: 100vh;
  font-family: Lato, sans-serif;
  display: flex;
}

main {
  flex: 1 0;
  position: relative;
}

header {
  background-color: var(--header-background);
  z-index: 10000;
  width: 100%;
  height: 5rem;
  position: fixed;
  top: 0;
}

.header-nav {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.logo-container {
  flex: 1 0;
  height: 100%;
}

.logo-link {
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  display: inline-flex;
}

.logo-link:hover img {
  transform: translateX(.5rem);
}

.logo {
  height: 3rem;
  transition: all .2s;
}

.account-container {
  flex: 1 0;
  justify-content: flex-end;
  height: 100%;
  display: flex;
}

.user-img {
  object-fit: cover;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  transition: all .2s;
}

.account-button {
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  padding: 0 2rem;
  transition: all .2s;
  display: inline-flex;
}

.account-button:hover, .account-button:active, .account-button:focus-visible, .account-button[data-pressed] {
  transform: scale(1.05);
}

.account-button:hover img, .account-button:active img, .account-button:focus-visible img, .account-button[data-pressed] img {
  outline: .1rem solid var(--brandcolor);
}

@media (width <= 30rem) {
  .account-button {
    padding: 0 1rem;
  }
}

.header-nav a {
  color: var(--header-text);
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  font-size: 1.25rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
  display: inline-flex;
}

.header-nav a:hover, .header-nav a:active, .header-nav a:focus-visible, .header-nav a[data-pressed] {
  text-shadow: 0 .7rem 1rem #000;
  color: var(--brandcolor);
  transform: scale(1.05);
}

@media (width <= 30rem) {
  .header-nav a {
    padding: 0 1rem;
  }
}

.header-nav a.cta {
  justify-content: center;
  width: 11rem;
  font-size: 1.15rem;
  position: relative;
}

.header-nav a.cta:before {
  content: "";
  background-color: var(--brandcolor);
  z-index: -1;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  position: absolute;
  top: -1rem;
  right: 2rem;
}

.header-nav a.cta:hover:before, .header-nav a.cta:active:before, .header-nav a.cta:focus-visible:before {
  background-color: var(--accent-scheme);
}

@media (width <= 30rem) {
  .header-nav a.cta {
    width: 9rem;
  }

  .header-nav a.cta:before {
    content: "";
    right: 1rem;
  }
}

#account-menu {
  background-image: var(--menu-background);
  z-index: -1;
  border-bottom-left-radius: 1rem;
  flex-direction: column;
  min-width: 25rem;
  padding: 1rem 0;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 5rem;
  right: 0;
}

#account-menu[data-hidden] {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-5px);
}

#account-menu hr {
  border: 0;
  border-top: 2px solid var(--accent-light);
  margin: .5rem 0;
}

header + * {
  margin-top: 5rem;
}

.account-nav, .admin-nav {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.menu-item {
  border-left: 0 solid var(--menu-text);
  color: var(--menu-text);
  text-transform: uppercase;
  padding: 1rem 4rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s;
}

.menu-item.link--active, .menu-item:hover, .menu-item:active {
  border-left: 4px solid var(--menu-text) !important;
}

.menu-item.link--active span {
  transform: translateX(-4px);
}

.menu-item:hover span, .menu-item:active span {
  transform: translateX(4px);
}

.menu-item span {
  align-items: center;
  gap: 2rem;
  transition: all .3s;
  display: flex;
}

.menu-item svg {
  fill: var(--menu-text);
  width: 1.9rem;
  height: 1.9rem;
}

.menu-heading {
  text-transform: uppercase;
  color: var(--menu-text);
  border-bottom: 2px solid var(--accent-light);
  padding: 2rem 4rem .5rem;
  font-size: .9rem;
}

.footer {
  background-color: var(--background);
  grid-row-gap: .75rem;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 3rem 4rem;
  font-size: 1rem;
  display: grid;
}

@media (width <= 50em) {
  .footer {
    grid-row-gap: 1.25rem;
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.footer__logo {
  grid-row: 1 / 3;
  align-self: center;
}

@media (width <= 50rem) {
  .footer__logo {
    grid-row: 1;
  }
}

.footer__logo img {
  height: 3rem;
}

.footer__nav {
  gap: 1.5rem;
  list-style: none;
  display: flex;
}

@media (width <= 23rem) {
  .footer__nav {
    flex-direction: column;
  }
}

.footer__nav a {
  color: #777;
  transition: all .2s;
  text-decoration: none !important;
}

.footer__nav a:hover, .footer__nav a:active {
  color: var(--brandcolor);
}

.footer__copyright {
  color: #999;
  justify-self: end;
}

@media (width <= 50rem) {
  .footer__copyright {
    justify-self: center;
  }
}

.alert {
  z-index: 9999;
  color: #fff;
  text-align: center;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 1.6rem 5rem;
  font-size: 1.25rem;
  font-weight: 400;
  position: fixed;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2rem 4rem #00000040;
}

.alert-success {
  background-color: #20bf6b;
}

.alert-error {
  background-color: #eb4d4b;
}

main.error {
  justify-content: center;
  align-items: center;
  display: flex;
}

.error .container {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 80rem;
  display: flex;
}

.error-heading {
  text-transform: uppercase;
  letter-spacing: .1rem;
  color: #eb4d4b;
  font-size: 2.75rem;
  font-weight: 700;
  line-height: 1.3;
  display: inline-block;
}

.error-message {
  max-width: 50rem;
  font-size: 2rem;
  font-weight: 700;
}

main.tour-overview, .card-container {
  grid-template-rows: min-content min-content;
  grid-template-columns: repeat(auto-fill, 20rem);
  justify-content: center;
  display: grid;
}

main.tour-overview {
  gap: 1rem 3rem;
  width: min(100%, 100rem);
  margin-left: auto;
  margin-right: auto;
  padding: min(4rem, 5vw);
}

.card-container {
  grid-gap: 3rem;
  grid-column: 1 / -1;
}

.overview-header {
  flex-wrap: wrap;
  grid-column: 1 / -1;
  gap: 1rem;
  display: flex;
}

.overview-header h1 {
  flex: 1;
  font-size: 2rem;
  font-weight: 400;
}

@media (width <= 50rem) {
  .overview-header h1 {
    flex: 1 0 100%;
  }
}

.overview-header button {
  color: var(--brandcolor);
  border: none;
  border-bottom: 1px solid var(--brandcolor);
  cursor: pointer;
  background: unset;
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  font-size: 1.25rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.overview-header button:hover, .overview-header button[data-pressed] {
  background-color: var(--brandcolor);
  color: var(--background-highlight);
  border-radius: .25rem;
  transform: translateY(-2px);
  box-shadow: 0 1rem 2rem #00000026;
}

.overview-header button svg {
  fill: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

#query-container {
  grid-column: 1 / -1;
  height: 2.5rem;
  margin-bottom: 1rem;
  transition: all .2s;
  position: relative;
}

#query-container[data-hidden] {
  visibility: hidden;
  height: 0;
  margin-bottom: 0;
}

#query-container[data-hidden] #sort, #query-container[data-hidden] #filter {
  opacity: 0;
}

#sort, #filter {
  justify-content: end;
  gap: .35rem;
  width: 100%;
  transition: all .2s;
  display: flex;
  position: absolute;
}

@media (width <= 50rem) {
  #sort, #filter {
    justify-content: start;
  }
}

#sort[data-hidden], #filter[data-hidden] {
  visibility: hidden;
  opacity: 0;
}

#sort label, #filter label {
  background-color: var(--background-highlight);
  cursor: pointer;
  padding: .5rem;
  font-size: 1.2rem;
  transition: all .4s;
}

#sort label:first-of-type, #filter label:first-of-type {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

#sort label:last-of-type, #filter label:last-of-type {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

@media (hover: hover) {
  #sort label:hover, #filter label:hover {
    background-color: var(--accent-scheme);
    transform: scale(1.05);
  }
}

#sort label.active, #filter label.active {
  background-color: var(--brandcolor);
  color: var(--background-highlight);
  transform: scale(1.05);
}

#sort label:focus-within, #filter label:focus-within {
  outline: 2px solid Highlight;
}

@media (-webkit-device-pixel-ratio >= 0) {
  #sort label:focus-within, #filter label:focus-within {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

@supports selector(:has(*)) {
  #sort label:focus-within, #filter label:focus-within {
    outline: unset;
  }

  :is(#sort label:has(input:focus-visible), #filter label:has(input:focus-visible)) {
    outline: 2px solid Highlight;
  }

  @media (-webkit-device-pixel-ratio >= 0) {
    :is(#sort label:has(input:focus-visible), #filter label:has(input:focus-visible)) {
      outline-style: auto;
      outline-color: -webkit-focus-ring-color;
    }
  }
}

#sort input, #filter input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.card {
  background-color: var(--background-highlight);
  color: var(--text);
  cursor: pointer;
  border-radius: 1rem;
  flex-direction: column;
  align-self: start;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem #0000001a;
}

.card:hover, .card:active, .card:focus-visible, .card:hover .card-picture-img, .card:active .card-picture-img, .card:focus-visible .card-picture-img {
  transform: scale(1.05);
}

.card:hover .card-title, .card:active .card-title, .card:focus-visible .card-title {
  transform: translateX(-1rem);
}

.card-header {
  position: relative;
}

.card-picture-container {
  clip-path: circle(farthest-side at 30% -200%);
  height: 16rem;
}

.card-picture-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all .3s;
}

.card-title {
  color: #fff;
  text-align: right;
  z-index: 10;
  width: 70%;
  font-size: 1.5rem;
  font-weight: 300;
  transition: all .3s;
  position: absolute;
  bottom: 1rem;
  right: 2rem;
}

.card-title span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-image: linear-gradient(to right bottom, #91c8e384, var(--brandcolor));
  border-radius: .5rem;
  padding: .25rem .6rem;
  line-height: 1.4;
}

.card-details {
  flex: 1;
  grid-template-rows: 1fr min-content;
  grid-template-columns: auto auto;
  gap: 1.25rem .8rem;
  padding: 1.25rem 1.5rem;
  display: grid;
}

.card-text {
  grid-column: 1 / -1;
  font-size: 1.1rem;
  font-style: italic;
}

.card-data {
  align-items: center;
  font-size: 1rem;
  display: flex;
}

.card-data svg {
  fill: var(--brandcolor);
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
}

.card-footer {
  background-color: var(--background-deemphasized);
  grid-template-columns: auto 1fr;
  grid-auto-flow: column;
  gap: .5rem;
  padding: 1.25rem 1.5rem;
  font-size: 1rem;
  display: grid;
}

.card-footer .value {
  font-weight: 600;
}

.card-footer .text {
  color: #a7a7a7;
}

.card-footer .button {
  text-transform: uppercase;
  background-color: var(--brandcolor);
  color: #fff;
  border-radius: 10rem;
  grid-row: 1 / 3;
  place-self: center end;
  padding: .6rem 1.5rem;
  font-size: 1rem;
  transition: all .4s;
  display: inline-block;
  position: relative;
}

.card-footer .button:hover {
  transform: scale(1.05);
  box-shadow: 0 1rem 2rem #00000026;
}

.card-footer .button:active {
  box-shadow: 0 .5rem 1rem #00000026;
}

.card-footer .button:focus {
  background-color: var(--accent-dark);
}

main.tour {
  grid-template-rows: min-content auto auto 1fr;
  grid-template-columns: 1fr 4vw 1fr;
  grid-auto-flow: dense;
  width: 100vw;
  max-width: 100%;
  display: grid;
}

@media (width <= 60rem) {
  main.tour {
    grid-template-columns: unset;
  }
}

@media (width >= 155rem) {
  main.tour {
    width: 150rem;
    margin: 0 auto;
  }
}

section.tour-header {
  grid-column: 1 / -1;
  max-width: 105rem;
  height: calc(100vh - 5rem);
  position: relative;
}

@media (width >= 155rem) {
  section.tour-header {
    max-height: 60rem;
    margin: 0 auto;
  }
}

@media (width <= 60rem) and (orientation: portrait) {
  section.tour-header {
    max-height: min(90vw + 8rem, 100vh - 5rem);
  }
}

.header-img {
  object-fit: cover;
  object-position: 50% 50%;
  border-bottom-right-radius: 25% 100%;
  width: 98%;
  height: calc(100% - 2rem);
  display: block;
}

@media (width >= 155rem) {
  .header-img {
    border-bottom-left-radius: 5rem;
  }
}

@media (width <= 60rem) and (orientation: portrait) {
  .header-img {
    border-bottom-right-radius: unset;
    clip-path: circle(farthest-side at 30% -200%);
    width: 100%;
    max-height: calc(100% - 8rem);
  }
}

.header-overlay {
  flex-direction: column;
  align-items: end;
  gap: 1rem;
  max-width: calc(100vw + -1 * min(3rem, 5vw));
  display: flex;
  position: absolute;
  bottom: 3rem;
  right: min(3rem, 5vw);
}

.header-title {
  color: #fff;
  text-align: right;
  width: 70%;
  margin-bottom: 2rem;
  font-size: min(3rem, 10vw);
  font-weight: 300;
}

@media (width <= 30em) {
  .header-title {
    width: unset;
  }
}

.header-title span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-image: linear-gradient(to right bottom, #91c8e384, var(--brandcolor));
  border-radius: .5rem;
  padding: .4rem .75rem;
  line-height: 1.4;
}

.header-data {
  text-transform: uppercase;
  text-shadow: 0 .5rem 2rem #00000026;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
}

.header-data svg {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
  margin-right: .8rem;
}

section.overview {
  background-color: var(--background-deemphasized);
  grid-column: 1 / 2;
  grid-template-columns: 1fr max-content 1fr;
  row-gap: 2.25rem;
  padding: max(5vw, 2rem) 8vw;
  display: grid;
  position: relative;
}

@media (width <= 60rem) {
  section.overview {
    clip-path: ellipse(120% farthest-side at 45%);
  }
}

section.overview:before, section.overview:after {
  content: "";
  background-color: inherit;
  z-index: -1;
  width: 100%;
  height: 5rem;
  position: absolute;
}

@media (width <= 60rem) {
  section.overview:before, section.overview:after {
    content: unset;
  }
}

section.overview:before {
  top: -5rem;
}

@media (width >= 155rem) {
  section.overview:before {
    border-top-left-radius: 5rem;
  }
}

section.overview:after {
  bottom: -5rem;
}

section.overview > * {
  grid-column: 2 / 3;
}

.overview-heading {
  text-transform: uppercase;
  letter-spacing: .1rem;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.3;
  display: inline-block;
}

.overview-data {
  align-items: center;
  font-size: 1.1rem;
  font-weight: 400;
  display: flex;
}

.overview-data svg {
  fill: var(--brandcolor);
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 1.25rem;
}

.overview-data .label {
  text-transform: uppercase;
  margin-right: 2.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.overview-data .text {
  text-transform: capitalize;
}

section.description {
  flex-direction: column;
  grid-area: 2 / 2 / span 2 / 4;
  gap: 2rem;
  height: min-content;
  padding: max(5vw, 2rem) 8vw;
  display: flex;
}

@media (width <= 60rem) {
  section.description {
    grid-column: unset;
    grid-row: unset;
  }
}

.description-heading {
  text-transform: uppercase;
  letter-spacing: .1rem;
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.3;
}

section.description p {
  font-size: 1.25rem;
}

section.cta-small {
  flex-direction: column;
  display: flex;
}

section.cta-small:before {
  content: "";
  flex: 1;
}

.cta-small .container {
  background-color: var(--background-highlight);
  border-radius: 1rem;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  width: calc(100% - 8vw);
  max-width: 35rem;
  margin: 5vw auto;
  padding: 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
  display: grid;
  position: sticky;
  bottom: 25vh;
}

.cta-small-heading {
  text-transform: uppercase;
  letter-spacing: .1rem;
  grid-column: 1 / -1;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.3;
}

.cta-small p {
  grid-column: 1 / -1;
}

.cta-small button, .cta-small a {
  background-color: var(--brandcolor);
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 10rem;
  grid-column: 2 / 3;
  padding: .6rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .4s;
}

.cta-small button:hover, .cta-small a:hover {
  transform: scale(1.05);
  box-shadow: 0 1rem 2rem #00000026;
}

.cta-small button:active, .cta-small a:active {
  box-shadow: 0 .5rem 1rem #00000026;
}

.cta-small button:focus, .cta-small a:focus {
  background-color: var(--accent-dark);
}

section.pictures {
  z-index: 1000;
  flex-direction: column;
  grid-area: 3 / 1 / span 3 / 3;
  margin-bottom: -5rem;
  display: flex;
}

@media (width <= 60rem) {
  section.pictures {
    grid-column: unset;
    grid-row: unset;
    margin-bottom: unset;
  }
}

section.pictures img {
  object-fit: cover;
  width: 100%;
  display: block;
}

section.pictures img:first-child {
  border-top-right-radius: 10vw;
}

@media (width <= 60rem) {
  section.pictures img:first-child {
    border-top-right-radius: unset;
    clip-path: ellipse(140% farthest-side at 45% bottom);
  }
}

section.pictures img:last-child {
  border-bottom-right-radius: 10vw;
}

@media (width <= 60rem) {
  section.pictures img:last-child {
    border-bottom-right-radius: unset;
    clip-path: ellipse(140% farthest-side at 55% 0);
  }
}

section.guide {
  background-color: var(--background-deemphasized);
  grid-column: 3 / 4;
  padding: 5vw 8vw;
  font-size: 1.1rem;
  font-weight: 400;
  position: relative;
}

section.guide:before, section.guide:after {
  content: "";
  background-color: inherit;
  position: absolute;
}

section.guide:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
}

@media (width <= 60rem) {
  section.guide:before {
    height: 5rem;
    top: -5rem;
    left: 0;
  }
}

section.guide:after {
  content: unset;
  z-index: 1000;
  clip-path: ellipse(75% farthest-side at 35% 0);
  width: 100%;
  height: 1rem;
  bottom: -1rem;
  left: 0;
}

@media (width <= 60rem) {
  section.guide:after {
    content: "";
  }

  section.guide {
    grid-column: unset;
  }
}

@media (width >= 155rem) {
  section.guide {
    border-top-right-radius: 5rem;
  }
}

.guide-heading {
  text-transform: uppercase;
  letter-spacing: .1rem;
  margin-bottom: 1.5rem;
  font-size: min(1.75rem, 8vw);
  font-weight: 700;
  line-height: 1.3;
}

.guide-img {
  shape-outside: circle(50%);
  float: right;
  border-radius: 50%;
  width: 30%;
  min-width: 9rem;
  margin: 0 0 2vw 2vw;
}

.tour-footer {
  background-image: linear-gradient(to right bottom, var(--accent-light), var(--accent-dark));
  grid-column: 1 / -1;
  overflow: hidden;
}

@media (width >= 155rem) {
  .tour-footer {
    border-bottom-right-radius: 5rem;
    border-bottom-left-radius: 5rem;
  }
}

section.map {
  flex-direction: column;
  height: min(45rem, 100vh - 5rem);
  display: flex;
  position: relative;
}

#map {
  background: var(--background-deemphasized);
  clip-path: ellipse(350% farthest-side at 100% 0);
  flex: 1;
  width: 100%;
}

.marker {
  cursor: pointer;
  background-image: url("pin.b5702467.png");
  background-size: cover;
  width: 32px;
  height: 40px;
}

.mapPopup {
  text-align: center;
  font-family: Lato, sans-serif;
  font-size: 1rem;
}

.leaflet-bar {
  border: 2px solid var(--text) !important;
}

.leaflet-bar a {
  background-color: var(--background);
  color: var(--text);
  border-bottom: 1px solid var(--text);
}

.leaflet-bar a:hover, .leaflet-bar a:active, .leaflet-bar a:focus-visible {
  background-color: var(--background-highlight);
}

.map-attribution {
  color: var(--text);
  text-align: end;
  font-size: .8rem;
}

.map-attribution a {
  color: #000;
  text-decoration: none;
}

section.reviews {
  z-index: 1000;
  width: 100%;
  position: relative;
}

.reviews-container {
  grid-column-gap: 6rem;
  scroll-behavior: smooth;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  grid-auto-flow: column;
  padding: 3rem 0;
  display: grid;
  overflow-x: scroll;
}

.reviews-container:before, .reviews-container:after {
  content: "";
  width: 2rem;
}

.reviews-container.active {
  scroll-snap-type: unset;
  scroll-behavior: unset;
  cursor: grabbing;
}

@media (width <= 60rem) {
  .reviews-container {
    scroll-snap-type: x mandatory;
  }
}

.review-card {
  background-color: var(--background);
  scroll-snap-align: center;
  pointer-events: none;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;
  width: 30rem;
  padding: 3rem;
  display: flex;
  box-shadow: 0 1.5rem 4rem #00000026;
}

.review-user {
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.review-user img {
  border-radius: 50%;
  height: 4.5rem;
  margin-right: 1.5rem;
}

.review-user h6 {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 700;
}

.review-text {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: 400;
}

.review-rating {
  gap: .5rem;
  display: flex;
}

.review-rating svg {
  width: 2rem;
  height: 2rem;
}

.active-star {
  fill: #decf31;
}

.inactive-star {
  fill: #bbb;
}

section.cta-large {
  width: 100%;
  padding: 10rem 8vw;
}

.cta-large .container {
  background-color: var(--background-highlight);
  border-radius: 2rem;
  grid-template-rows: 1fr auto auto 1fr;
  grid-template-columns: auto 1fr auto auto 1fr;
  gap: 2rem;
  max-width: 90rem;
  min-height: 20rem;
  margin: 0 auto;
  display: grid;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3rem 8rem .5rem #00000026;
}

@media (width <= 60rem) {
  .cta-large .container {
    grid-template-columns: unset;
    grid-template-rows: auto 1fr auto auto auto 1fr;
    padding: 0 2rem 2rem;
  }
}

.cta-large-images {
  grid-row: 1 / -1;
  width: 15rem;
}

@media (width <= 60rem) {
  .cta-large-images {
    grid-row: unset;
    width: unset;
    grid-column: 1 / -1;
    height: 15rem;
  }
}

.cta-large-images > * {
  border-radius: 50%;
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 50%;
  left: 0;
  box-shadow: 1rem .5rem 3rem #00000026;
}

.cta-large-images > :first-child {
  z-index: 10;
  transform: translate(-35%, -50%);
}

.cta-large-images > :nth-child(2) {
  z-index: 9;
  transform: translate(-10%, -50%)scale(.97);
}

.cta-large-images > :nth-child(3) {
  z-index: 8;
  transform: translate(15%, -50%)scale(.94);
}

@media (width <= 60rem) {
  .cta-large-images > * {
    top: 0;
    left: 50%;
  }

  .cta-large-images > :first-child {
    z-index: 10;
    transform: translate(-75%, 10%);
  }

  .cta-large-images > :nth-child(2) {
    z-index: 9;
    transform: translate(-50%, 10%)scale(.97);
  }

  .cta-large-images > :nth-child(3) {
    z-index: 8;
    transform: translate(-25%, 10%)scale(.94);
  }
}

.cta-logo {
  background-image: linear-gradient(to right bottom, var(--background-highlight), var(--accent-scheme));
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.cta-logo img {
  width: 100%;
}

.cta-large-heading {
  text-transform: uppercase;
  letter-spacing: .1rem;
  grid-area: 2 / 3 / 3 / 5;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.3;
  display: inline-block;
}

@media (width <= 60rem) {
  .cta-large-heading {
    text-align: center;
    grid-area: 3 / 1 / 4 / -1;
    justify-self: center;
  }
}

.cta-large p {
  grid-area: 3 / 3 / 4 / 4;
  font-size: 1.3rem;
  font-weight: 400;
}

@media (width <= 60rem) {
  .cta-large p {
    grid-area: 4 / 1 / 5 / -1;
  }
}

.cta-large button, .cta-large a {
  background-color: var(--brandcolor);
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 10rem;
  grid-area: 3 / 4 / 4 / 5;
  align-self: end;
  padding: 1.4rem 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .4s;
}

.cta-large button:hover, .cta-large a:hover {
  transform: scale(1.05);
  box-shadow: 0 1rem 2rem #00000026;
}

.cta-large button:active, .cta-large a:active {
  box-shadow: 0 .5rem 1rem #00000026;
}

.cta-large button:focus, .cta-large a:focus {
  background-color: var(--accent-dark);
}

@media (width <= 60rem) {
  .cta-large button, .cta-large a {
    grid-area: 5 / 1 / 6 / -1;
    justify-self: center;
  }
}

main.form, main.account {
  padding: min(4rem, 5vw);
}

.form .container, .account .container {
  background-color: var(--background-highlight);
  border-radius: 1rem;
  max-width: 55rem;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 2.5rem 8rem 2rem #00000012;
}

.account .container {
  max-width: 80rem;
  display: flex;
}

.form .container {
  padding-bottom: 4rem;
}

.side-account-menu {
  background-image: var(--menu-background);
  flex: 0;
  min-width: 25rem;
  padding: 3rem 0;
}

@media (width <= 70rem) {
  .side-account-menu {
    display: none;
  }
}

.account-content-container {
  flex: 1;
  min-width: 0;
  padding-bottom: 4rem;
}

.form-heading, .account-heading {
  text-transform: uppercase;
  letter-spacing: .1rem;
  margin-bottom: 2rem;
  padding: 4rem 10%;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.3;
}

.form-heading:not(:first-of-type), .account-heading:not(:first-of-type) {
  border-top: 2px solid var(--accent-scheme);
  margin-top: 10rem;
}

.form form, .account form, .account-table-container {
  flex-direction: column;
  gap: 2.5rem;
  padding: 0 10%;
  display: flex;
}

form label {
  margin-bottom: .75rem;
  font-size: 1.2rem;
  font-weight: 700;
  display: block;
}

form input {
  color: inherit;
  background-color: var(--background-deemphasized);
  border: 3px solid #0000;
  border-left: none;
  border-right: none;
  border-radius: .5rem;
  width: 100%;
  padding: 1.25rem 1.75rem;
  font-family: inherit;
  font-size: 1.1rem;
  transition: all .3s;
  display: block;
}

form input:focus {
  border-bottom: 3px solid #55c57a;
  outline: none;
}

form input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}

form input::-webkit-input-placeholder {
  color: #bbb;
}

.photo-upload {
  align-items: center;
  font-size: 1.2rem;
  display: flex;
}

.photo-upload img {
  object-fit: cover;
  border-radius: 50%;
  width: 7.5rem;
  height: 7.5rem;
  margin-right: 2rem;
}

.photo-upload input {
  opacity: 0;
  z-index: -1;
  width: .1px;
  height: .1px;
  position: absolute;
  overflow: hidden;
}

.photo-upload input:focus + label {
  outline: 3px solid var(--brandcolor);
  outline-offset: 3px;
}

.photo-upload label {
  color: var(--brandcolor);
  border-bottom: 1px solid var(--brandcolor);
  cursor: pointer;
  padding: 3px;
  text-decoration: none;
  transition: all .2s;
}

.photo-upload label:hover {
  background-color: var(--brandcolor);
  color: var(--background-highlight);
  border-radius: .25rem;
  transform: translateY(-2px);
  box-shadow: 0 1rem 2rem #00000026;
}

form .submit-group {
  justify-content: end;
  align-items: center;
  display: flex;
}

form .signup-button, form .forgot-password-button {
  color: var(--brandcolor);
  border-bottom: 1px solid var(--brandcolor);
  cursor: pointer;
  margin-right: auto;
  padding: 3px;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

form .signup-button:hover, form .forgot-password-button:hover {
  background-color: var(--brandcolor);
  color: var(--background-highlight);
  border-radius: .25rem;
  transform: translateY(-2px);
  box-shadow: 0 1rem 2rem #00000026;
}

form .forgot-password-button {
  margin-top: .5rem;
  display: none;
}

.form-button {
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--brandcolor);
  color: #fff;
  border: none;
  border-radius: 10rem;
  padding: 1.4rem 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .4s;
  display: block;
  position: relative;
}

.form-button:hover {
  transform: scale(1.05);
  box-shadow: 0 1rem 2rem #00000026;
}

.form-button:active {
  box-shadow: 0 .5rem 1rem #00000026;
}

.form-button:focus {
  background-color: var(--accent-dark);
}

.account .form-button {
  padding: .6rem 1.5rem;
  font-size: 1rem;
}

.booking-card {
  background-color: var(--background-deemphasized);
  border-radius: 1rem;
  grid-template-rows: auto 1fr 1fr 0;
  grid-template-columns: 3fr 4fr;
  gap: .5rem;
  height: 10rem;
  display: grid;
  overflow: hidden;
}

@media (width <= 45rem) {
  .booking-card {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= 35rem) {
  .booking-card {
    grid-template-columns: unset;
    height: unset;
  }
}

.booking-title {
  color: #fff;
  text-align: center;
  z-index: 10;
  grid-area: 1 / 1 / 2 / -1;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  font-size: 1.5rem;
  font-weight: 300;
  display: flex;
}

@media (width <= 35rem) {
  .booking-title {
    text-align: unset;
    border-bottom: .1rem solid var(--accent-scheme);
    margin: .25rem;
    display: block;
  }
}

.booking-title span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-image: linear-gradient(to right bottom, #91c8e384, var(--brandcolor));
  border-radius: .5rem;
  padding: .25rem .6rem;
  line-height: 1.4;
}

@media (width <= 35rem) {
  .booking-title span {
    background-image: unset;
  }
}

.booking-picture-container {
  clip-path: circle(farthest-side at 0 30%);
  grid-area: 1 / 1 / -1 / 2;
  width: 100%;
  height: 100%;
  max-height: 10rem;
}

@media (width <= 35rem) {
  .booking-picture-container {
    display: none;
  }
}

.booking-picture-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.booking-data {
  align-items: center;
  min-height: 0;
  padding: .5rem;
  font-size: 1rem;
  display: flex;
}

.booking-data svg {
  fill: var(--brandcolor);
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
}

/*# sourceMappingURL=styles.css.map */
