@use 'palette';

@mixin shared {
	--brandcolor: #{palette.$blue-5};
}

@mixin light {
	@include shared;

	--background: #{palette.$grey-1};
	--background-deemphasized: #{palette.$grey-2};
	--background-highlight: #{palette.$white};
	--text: #{palette.$grey-8};
	--accent-light: #{palette.$blue-3};
	--accent-dark: #{palette.$blue-5};
	--accent-scheme: var(--accent-light);
	--header-background: #{palette.$grey-8};
	--header-text: #{palette.$white};
	--menu-background: linear-gradient(to right bottom, var(--accent-light), var(--accent-dark));
	--menu-text: #{palette.$white};
}

@mixin dark {
	@include shared;

	--background: #{palette.$grey-9};
	--background-deemphasized: #{mix(palette.$grey-9, palette.$black, 50%)};
	--background-highlight: #{palette.$black};
	--text: #{palette.$grey-3};
	--accent-light: #{palette.$blue-5};
	--accent-dark: #{palette.$blue-8};
	--accent-scheme: var(--accent-dark);
	--header-background: #{palette.$black};
	--header-text: #{palette.$grey-3};
	--menu-background: linear-gradient(to right bottom, var(--accent-light), var(--accent-dark));
	--menu-text: #{palette.$grey-2};
}
