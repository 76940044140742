@use 'colors';
@import 'npm:leaflet/dist/leaflet.css';

:root {
	@include colors.dark;

	@media (prefers-color-scheme: light) {
		@include colors.light;
	}
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
}

body {
	font-family: 'Lato', sans-serif;
	color: var(--text);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: var(--background);
}

main {
	flex: 1 0;
	position: relative;
}

// ##################

$header-height: 5rem;
header {
	position: fixed;
	top: 0;
	height: $header-height;
	width: 100%;
	background-color: var(--header-background);
	z-index: 10000;
}

.header-nav {
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo-container {
	flex: 1 0;
	height: 100%;
}

.logo-link {
	padding: 0 2rem;
	height: 100%;
	display: inline-flex;
	align-items: center;

	&:hover img {
		transform: translateX(0.5rem);
	}
}

.logo {
	height: 3rem;
	transition: all 0.2s;
}

.account-container {
	height: 100%;
	flex: 1 0;
	display: flex;
	justify-content: flex-end;
}

.user-img {
	height: $header-height + 2rem;
	width: $header-height + 2rem;
	border-radius: 50%;
	object-fit: cover;
	transition: all 0.2s;
}

.account-button {
	display: inline-flex;
	align-items: center;
	padding: 0 2rem;
	background: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s;

	&:hover,
	&:active,
	&:focus-visible,
	&[data-pressed] {
		transform: scale(1.05);

		img {
			outline: 0.1rem solid var(--brandcolor);
		}
	}

	@media (max-width: 30rem) {
		padding: 0 1rem;
	}
}

.header-nav a {
	height: 100%;
	padding: 0 2rem;
	color: var(--header-text);
	font-size: 1.25rem;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	transition: all 0.2s;
	font-weight: 400;

	&:hover,
	&:active,
	&:focus-visible,
	&[data-pressed] {
		transform: scale(1.05);
		text-shadow: 0 0.7rem 1rem black;
		color: var(--brandcolor);
	}

	@media (max-width: 30rem) {
		padding: 0 1rem;
	}
}

.header-nav a.cta {
	position: relative;
	width: 11rem;
	font-size: 1.15rem;
	justify-content: center;

	&::before {
		content: '';
		background-color: var(--brandcolor);
		position: absolute;
		top: -1rem;
		right: 2rem;
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		z-index: -1;
	}

	&:hover::before,
	&:active::before,
	&:focus-visible::before {
		background-color: var(--accent-scheme);
	}

	@media (max-width: 30rem) {
		width: 9rem;

		&::before {
			content: '';
			right: 1rem;
		}
	}
}

#account-menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: $header-height;
	right: 0;
	background-image: var(--menu-background);
	z-index: -1;
	border-bottom-left-radius: 1rem;
	padding: 1rem 0;
	min-width: 25rem;
	transition: all 0.2s;

	&[data-hidden] {
		visibility: hidden;
		opacity: 0;
		transform: translateY(-5px);
	}
}

#account-menu hr {
	border: 0;
	border-top: 2px solid var(--accent-light);
	margin: 0.5rem 0;
}

header + * {
	margin-top: $header-height;
}

// ##################

.account-nav,
.admin-nav {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.menu-item {
	border-left: 0 solid var(--menu-text);
	padding: 1rem 4rem;
	color: var(--menu-text);
	font-size: 1.1rem;
	text-transform: uppercase;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.3s;

	&.link--active,
	&:hover,
	&:active {
		border-left: 4px solid var(--menu-text) !important;
	}

	&.link--active span {
		transform: translateX(-4px);
	}

	&:hover span,
	&:active span {
		transform: translateX(4px);
	}
}

.menu-item span {
	transition: all 0.3s;
	display: flex;
	align-items: center;
	gap: 2rem;
}

.menu-item svg {
	height: 1.9rem;
	width: 1.9rem;
	fill: var(--menu-text);
}

.menu-heading {
	padding: 2rem 4rem 0.5rem;
	font-size: 0.9rem;
	text-transform: uppercase;
	color: var(--menu-text);
	border-bottom: 2px solid var(--accent-light);
}

// ##################

.footer {
	background-color: var(--background);
	padding: 3rem 4rem;
	font-size: 1rem;
	display: grid;
	grid-template-columns: auto auto;
	grid-row-gap: 0.75rem;
	justify-content: space-between;

	@media (max-width: 50em) {
		grid-template-columns: 1fr;
		grid-row-gap: 1.25rem;
		justify-items: center;
	}
}
.footer__logo {
	grid-row: 1 / 3;
	align-self: center;

	@media (max-width: 50rem) {
		grid-row: 1;
	}
}

.footer__logo img {
	height: 3rem;
}

.footer__nav {
	list-style: none;
	display: flex;
	gap: 1.5rem;

	@media (max-width: 23rem) {
		flex-direction: column;
	}
}

.footer__nav a {
	color: #777;
	text-decoration: none !important;
	transition: all 0.2s;

	&:hover,
	&:active {
		color: var(--brandcolor);
	}
}

.footer__copyright {
	justify-self: end;
	color: #999;

	@media (max-width: 50rem) {
		justify-self: center;
	}
}

// ##################

.alert {
	position: fixed;
	top: $header-height;
	left: 50%;
	transform: translateX(-50%);
	z-index: 9999;
	color: #fff;
	font-size: 1.25rem;
	font-weight: 400;
	text-align: center;
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	padding: 1.6rem 5rem;
	box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25);
}
.alert-success {
	background-color: #20bf6b;
}
.alert-error {
	background-color: #eb4d4b;
}

// ##################

main.error {
	display: flex;
	align-items: center;
	justify-content: center;
}

.error .container {
	width: 90vw;
	max-width: 80rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.error-heading {
	font-size: 2.75rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	line-height: 1.3;
	display: inline-block;
	color: #eb4d4b;
}

.error-message {
	font-size: 2rem;
	font-weight: 700;
	max-width: 50rem;
}

// ##################

main.tour-overview,
.card-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, 20rem);
	grid-template-rows: min-content min-content;
	justify-content: center;
}

main.tour-overview {
	padding: min(4rem, 5vw) min(4rem, 5vw);
	width: min(100%, 100rem);
	margin-left: auto;
	margin-right: auto;
	row-gap: 1rem;
	column-gap: 3rem;
}

.card-container {
	grid-column: 1/-1;
	grid-gap: 3rem;
}

.overview-header {
	grid-column: 1/-1;
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
}

.overview-header h1 {
	font-size: 2rem;
	font-weight: 400;
	flex: 1;

	@media (max-width: 50rem) {
		flex: 1 0 100%;
	}
}

.overview-header button {
	color: var(--brandcolor);
	text-decoration: none;
	border: none;
	border-bottom: 1px solid var(--brandcolor);
	transition: all 0.2s;
	cursor: pointer;
	background: unset;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 1.25rem;
	padding: 0.5rem;

	&:hover,
	&[data-pressed] {
		background-color: var(--brandcolor);
		color: var(--background-highlight);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
		transform: translateY(-2px);
		border-radius: 0.25rem;
	}
}

.overview-header button svg {
	height: 1.5rem;
	width: 1.5rem;
	fill: currentColor;
}

#query-container {
	position: relative;
	grid-column: 1/-1;
	height: 2.5rem;
	transition: all 0.2s;
	margin-bottom: 1rem;

	&[data-hidden] {
		visibility: hidden;
		height: 0;
		margin-bottom: 0;

		#sort,
		#filter {
			opacity: 0;
		}
	}
}

#sort,
#filter {
	display: flex;
	gap: 0.35rem;
	justify-content: end;
	transition: all 0.2s;
	position: absolute;
	width: 100%;

	@media (max-width: 50rem) {
		justify-content: start;
	}

	&[data-hidden] {
		visibility: hidden;
		opacity: 0;
	}
}

#sort label,
#filter label {
	background-color: var(--background-highlight);
	padding: 0.5rem;
	font-size: 1.2rem;
	cursor: pointer;
	transition: all 0.4s;

	&:first-of-type {
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
	}

	&:last-of-type {
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
	}

	@media (hover: hover) {
		&:hover {
			transform: scale(1.05);
			background-color: var(--accent-scheme);
		}
	}

	&.active {
		transform: scale(1.05);
		background-color: var(--brandcolor);
		color: var(--background-highlight);
	}

	&:focus-within {
		outline: 2px solid Highlight;

		@media (-webkit-min-device-pixel-ratio: 0) {
			outline-style: auto;
			outline-color: -webkit-focus-ring-color;
		}
	}

	@supports selector(:has(*)) {
		&:focus-within {
			outline: unset;
		}

		&:has(input:focus-visible) {
			outline: 2px solid Highlight;

			@media (-webkit-min-device-pixel-ratio: 0) {
				outline-style: auto;
				outline-color: -webkit-focus-ring-color;
			}
		}
	}
}

#sort input,
#filter input {
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0;
}

.card {
	border-radius: 1rem;
	overflow: hidden;
	background-color: var(--background-highlight);
	color: var(--text);
	box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
	transition: 0.3s all;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	text-decoration: none;
	font-weight: 400;
	align-self: start;

	&:hover,
	&:active,
	&:focus-visible {
		transform: scale(1.05);

		.card-picture-img {
			transform: scale(1.05);
		}

		.card-title {
			transform: translateX(-1rem);
		}
	}
}

.card-header {
	position: relative;
}

.card-picture-container {
	clip-path: circle(farthest-side at 30% -200%);
	height: 16rem;
}

.card-picture-img {
	object-fit: cover;
	height: 100%;
	width: 100%;
	transition: 0.3s all;
}

.card-title {
	color: #ffffff;
	font-weight: 300;
	font-size: 1.5rem;
	text-align: right;
	position: absolute;
	bottom: 1rem;
	right: 2rem;
	width: 70%;
	z-index: 10;
	transition: 0.3s all;
}

.card-title span {
	padding: 0.25rem 0.6rem;
	line-height: 1.4;
	box-decoration-break: clone;
	background-image: linear-gradient(to right bottom, #91c8e384, var(--brandcolor));
	border-radius: 0.5rem;
}

.card-details {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: 1fr min-content;
	column-gap: 0.8rem;
	row-gap: 1.25rem;
	padding: 1.25rem 1.5rem;
	flex: 1;
}

.card-text {
	grid-column: 1 / -1;
	font-size: 1.1rem;
	font-style: italic;
}

.card-data {
	font-size: 1rem;
	display: flex;
	align-items: center;
}

.card-data svg {
	margin-right: 0.5rem;
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--brandcolor);
}

.card-footer {
	background-color: var(--background-deemphasized);
	padding: 1.25rem 1.5rem;
	font-size: 1rem;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 0.5rem;
	grid-auto-flow: column;
}
.card-footer .value {
	font-weight: 600;
}
.card-footer .text {
	color: #a7a7a7;
}

.card-footer .button {
	padding: 0.6rem 1.5rem;
	font-size: 1rem;
	border-radius: 10rem;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	transition: all 0.4s;
	background-color: var(--brandcolor);
	color: #fff;
	grid-row: 1 / 3;
	justify-self: end;
	align-self: center;

	&:hover {
		transform: scale(1.05);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
	}

	&:active {
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	}

	&:focus {
		background-color: var(--accent-dark);
	}
}

// ##################

main.tour {
	display: grid;
	width: 100vw;
	max-width: 100%;
	grid-template-rows: min-content auto auto 1fr;
	grid-template-columns: 1fr 4vw 1fr;
	grid-auto-flow: dense;

	@media (max-width: 60rem) {
		grid-template-columns: unset;
	}

	@media (min-width: 155rem) {
		width: 150rem;
		margin: 0 auto;
	}
}

section.tour-header {
	position: relative;
	max-width: 105rem;
	grid-column: 1/-1;
	height: calc(100vh - $header-height);

	@media (min-width: 155rem) {
		margin: 0 auto;
		max-height: 60rem;
	}

	@media (max-width: 60rem) and (orientation: portrait) {
		max-height: min(90vw + 8rem, calc(100vh - $header-height));
	}
}

.header-img {
	object-fit: cover;
	height: calc(100% - 2rem);
	width: 98%;
	border-bottom-right-radius: 25% 100%;
	object-position: 50% 50%;
	display: block;

	@media (min-width: 155rem) {
		border-bottom-left-radius: 5rem;
	}

	@media (max-width: 60rem) and (orientation: portrait) {
		max-height: calc(100% - 8rem);
		width: 100%;
		border-bottom-right-radius: unset;
		clip-path: circle(farthest-side at 30% -200%);
	}
}

.header-overlay {
	position: absolute;
	bottom: 3rem;
	right: min(3rem, 5vw);
	max-width: calc(100vw - min(3rem, 5vw));
	display: flex;
	flex-direction: column;
	align-items: end;
	gap: 1rem;
}

.header-title {
	color: #fff;
	font-weight: 300;
	font-size: min(3rem, 10vw);
	text-align: right;
	width: 70%;
	margin-bottom: 2rem;

	@media (max-width: 30em) {
		width: unset;
	}
}

.header-title span {
	padding: 0.4rem 0.75rem;
	line-height: 1.4;
	box-decoration-break: clone;
	background-image: linear-gradient(to right bottom, #91c8e384, var(--brandcolor));
	border-radius: 0.5rem;
}

.header-data {
	font-size: 1rem;
	font-weight: 700;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	text-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
}

.header-data svg {
	margin-right: 0.8rem;
	height: 2rem;
	width: 2rem;
	fill: currentColor;
}

section.overview {
	position: relative;
	padding: max(5vw, 2rem) 8vw;
	background-color: var(--background-deemphasized);
	grid-column: 1/2;
	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	row-gap: 2.25rem;

	@media (max-width: 60rem) {
		clip-path: ellipse(120% farthest-side at 45%);
	}

	&::before,
	&::after {
		content: '';
		background-color: inherit;
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 5rem;

		@media (max-width: 60rem) {
			content: unset;
		}
	}

	&::before {
		top: -5rem;

		@media (min-width: 155rem) {
			border-top-left-radius: 5rem;
		}
	}

	&::after {
		bottom: -5rem;
	}
}

section.overview > * {
	grid-column: 2/3;
}

.overview-heading {
	font-size: 1.75rem;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1rem;
	line-height: 1.3;
	display: inline-block;
	margin-bottom: 1.25rem;
}

.overview-data {
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	font-weight: 400;
}

.overview-data svg {
	height: 1.75rem;
	width: 1.75rem;
	margin-right: 1.25rem;
	fill: var(--brandcolor);
}

.overview-data .label {
	font-weight: 700;
	margin-right: 2.25rem;
	text-transform: uppercase;
	font-size: 1rem;
}

.overview-data .text {
	text-transform: capitalize;
}

section.description {
	padding: max(5vw, 2rem) 8vw;
	grid-column: 2/4;
	grid-row: 2 / span 2;
	height: min-content;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	@media (max-width: 60rem) {
		grid-column: unset;
		grid-row: unset;
	}
}

.description-heading {
	font-size: 1.75rem;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1rem;
	line-height: 1.3;
	margin-bottom: 1.5rem;
}

section.description p {
	font-size: 1.25rem;
}

section.cta-small {
	display: flex;
	flex-direction: column;

	&::before {
		content: '';
		flex: 1;
	}
}

.cta-small .container {
	position: sticky;
	bottom: 25vh;
	width: calc(100% - 8vw);
	max-width: 35rem;
	margin: 5vw auto;
	padding: 1.5rem;
	border-radius: 1rem;
	background-color: var(--background-highlight);
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr auto;
	font-size: 1.3rem;
	font-weight: 400;
}

.cta-small-heading {
	font-size: 1.75rem;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1rem;
	line-height: 1.3;
	grid-column: 1/-1;
}

.cta-small p {
	grid-column: 1/-1;
}

.cta-small button,
.cta-small a {
	grid-column: 2/3;
	background-color: var(--brandcolor);
	color: #fff;
	padding: 0.6rem 1.5rem;
	border-radius: 10rem;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 400;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	transition: all 0.4s;

	&:hover {
		transform: scale(1.05);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
	}

	&:active {
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	}

	&:focus {
		background-color: var(--accent-dark);
	}
}

section.pictures {
	display: flex;
	z-index: 1000;
	flex-direction: column;
	grid-column: 1/3;
	grid-row: 3 / span 3;
	margin-bottom: -5rem;

	@media (max-width: 60rem) {
		grid-column: unset;
		grid-row: unset;
		margin-bottom: unset;
	}
}

section.pictures img {
	display: block;
	width: 100%;
	object-fit: cover;

	&:first-child {
		border-top-right-radius: 10vw;

		@media (max-width: 60rem) {
			border-top-right-radius: unset;
			clip-path: ellipse(140% farthest-side at 45% bottom);
		}
	}

	&:last-child {
		border-bottom-right-radius: 10vw;

		@media (max-width: 60rem) {
			border-bottom-right-radius: unset;
			clip-path: ellipse(140% farthest-side at 55% top);
		}
	}
}

section.guide {
	position: relative;
	padding: 5vw 8vw;
	background-color: var(--background-deemphasized);
	grid-column: 3/4;
	font-size: 1.1rem;
	font-weight: 400;

	&::before,
	&::after {
		content: '';
		position: absolute;
		background-color: inherit;
	}

	&::before {
		content: '';
		height: 100%;
		width: 100%;
		top: 0;
		left: -100%;

		@media (max-width: 60rem) {
			height: 5rem;
			top: -5rem;
			left: 0;
		}
	}

	&::after {
		content: unset;
		height: 1rem;
		width: 100%;
		left: 0;
		bottom: -1rem;
		z-index: 1000;
		clip-path: ellipse(75% farthest-side at 35% top);

		@media (max-width: 60rem) {
			content: '';
		}
	}

	@media (max-width: 60rem) {
		grid-column: unset;
	}

	@media (min-width: 155rem) {
		border-top-right-radius: 5rem;
	}
}

.guide-heading {
	font-size: min(1.75rem, 8vw);
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1rem;
	line-height: 1.3;
	margin-bottom: 1.5rem;
}

.guide-img {
	width: 30%;
	min-width: 9rem;
	border-radius: 50%;
	shape-outside: circle(50%);
	float: right;
	margin: 0 0 2vw 2vw;
}

.tour-footer {
	grid-column: 1/-1;
	background-image: linear-gradient(to right bottom, var(--accent-light), var(--accent-dark));
	overflow: hidden;

	@media (min-width: 155rem) {
		border-bottom-left-radius: 5rem;
		border-bottom-right-radius: 5rem;
	}
}

section.map {
	position: relative;
	height: min(45rem, calc(100vh - $header-height));
	display: flex;
	flex-direction: column;
}

#map {
	background: var(--background-deemphasized);
	clip-path: ellipse(350% farthest-side at 100% 0%);
	width: 100%;
	flex: 1;
}

.marker {
	background-image: url('../pin.png');
	background-size: cover;
	width: 32px;
	height: 40px;
	cursor: pointer;
}

.mapPopup {
	text-align: center;
	font-family: 'Lato', sans-serif;
	font-size: 1rem;
}

.leaflet-bar {
	border: 2px solid var(--text) !important;
}

.leaflet-bar a {
	background-color: var(--background);
	color: var(--text);
	border-bottom: 1px solid var(--text);

	&:hover,
	&:active,
	&:focus-visible {
		background-color: var(--background-highlight);
	}
}

.map-attribution {
	color: var(--text);
	font-size: 0.8rem;
	text-align: end;
}

.map-attribution a {
	text-decoration: none;
	color: #000000;
}

section.reviews {
	position: relative;
	z-index: 1000;
	width: 100%;
}

.reviews-container {
	padding: 3rem 0;
	display: grid;
	grid-column-gap: 6rem;
	grid-auto-flow: column;
	overflow-x: scroll;
	scroll-behavior: smooth;
	user-select: none;
	cursor: pointer;

	&::before,
	&::after {
		content: '';
		width: 2rem;
	}

	&.active {
		scroll-snap-type: unset;
		scroll-behavior: unset;
		cursor: grabbing;
	}

	@media (max-width: 60rem) {
		scroll-snap-type: x mandatory;
	}
}

.review-card {
	width: 30rem;
	padding: 3rem;
	background-color: var(--background);
	border-radius: 1rem;
	box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
	scroll-snap-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	pointer-events: none;
}

.review-user {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
}

.review-user img {
	height: 4.5rem;
	border-radius: 50%;
	margin-right: 1.5rem;
}

.review-user h6 {
	font-size: 1.1rem;
	font-weight: 700;
	text-transform: uppercase;
}

.review-text {
	font-size: 1.1rem;
	margin-bottom: 2rem;
	font-style: italic;
	font-weight: 400;
}

.review-rating {
	display: flex;
	gap: 0.5rem;
}

.review-rating svg {
	height: 2rem;
	width: 2rem;
}

.active-star {
	fill: #decf31;
}

.inactive-star {
	fill: #bbb;
}

section.cta-large {
	width: 100%;
	padding: 10rem 8vw;
}

.cta-large .container {
	position: relative;
	max-width: 90rem;
	min-height: 20rem;
	margin: 0 auto;
	overflow: hidden;
	background-color: var(--background-highlight);
	border-radius: 2rem;
	box-shadow: 0 3rem 8rem 0.5rem rgba(0, 0, 0, 0.15);
	display: grid;
	grid-template-columns: auto 1fr auto auto 1fr;
	grid-template-rows: 1fr auto auto 1fr;
	gap: 2rem;

	@media (max-width: 60rem) {
		grid-template-columns: unset;
		grid-template-rows: auto 1fr auto auto auto 1fr;
		padding: 0 2rem 2rem;
	}
}

.cta-large-images {
	grid-row: 1/-1;
	width: 15rem;

	@media (max-width: 60rem) {
		grid-row: unset;
		grid-column: 1/-1;
		width: unset;
		height: 15rem;
	}
}

.cta-large-images > * {
	height: 15rem;
	width: 15rem;
	position: absolute;
	left: 0;
	top: 50%;
	border-radius: 50%;
	box-shadow: 1rem 0.5rem 3rem rgba(0, 0, 0, 0.15);

	&:nth-child(1) {
		transform: translate(-35%, -50%);
		z-index: 10;
	}

	&:nth-child(2) {
		transform: translate(-10%, -50%) scale(0.97);
		z-index: 9;
	}

	&:nth-child(3) {
		transform: translate(15%, -50%) scale(0.94);
		z-index: 8;
	}

	@media (max-width: 60rem) {
		top: 0;
		left: 50%;

		&:nth-child(1) {
			transform: translate(-75%, 10%);
			z-index: 10;
		}

		&:nth-child(2) {
			transform: translate(-50%, 10%) scale(0.97);
			z-index: 9;
		}

		&:nth-child(3) {
			transform: translate(-25%, 10%) scale(0.94);
			z-index: 8;
		}
	}
}

.cta-logo {
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: linear-gradient(to right bottom, var(--background-highlight), var(--accent-scheme));
}

.cta-logo img {
	width: 100%;
}

.cta-large-heading {
	grid-column: 3/5;
	grid-row: 2/3;
	font-size: 1.75rem;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1rem;
	line-height: 1.3;
	display: inline-block;

	@media (max-width: 60rem) {
		grid-row: 3/4;
		grid-column: 1/-1;
		justify-self: center;
		text-align: center;
	}
}

.cta-large p {
	grid-column: 3/4;
	grid-row: 3/4;
	font-size: 1.3rem;
	font-weight: 400;

	@media (max-width: 60rem) {
		grid-row: 4/5;
		grid-column: 1/-1;
	}
}

.cta-large button,
.cta-large a {
	grid-column: 4/5;
	grid-row: 3/4;
	align-self: end;
	background-color: var(--brandcolor);
	color: #fff;
	padding: 1.4rem 3rem;
	border-radius: 10rem;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 400;
	font-size: 1.2rem;
	border: none;
	cursor: pointer;
	transition: all 0.4s;

	&:hover {
		transform: scale(1.05);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
	}

	&:active {
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	}

	&:focus {
		background-color: var(--accent-dark);
	}

	@media (max-width: 60rem) {
		grid-row: 5/6;
		grid-column: 1/-1;
		justify-self: center;
	}
}

// ##################

main.form,
main.account {
	padding: min(4rem, 5vw) min(4rem, 5vw);
}

.form .container,
.account .container {
	background-color: var(--background-highlight);
	max-width: 55rem;
	margin: 0 auto;
	border-radius: 1rem;
	overflow: hidden;
	box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.07);
}

.account .container {
	display: flex;
	max-width: 80rem;
}

.form .container {
	padding-bottom: 4rem;
}

.side-account-menu {
	flex: 0;
	min-width: 25rem;
	background-image: var(--menu-background);
	padding: 3rem 0;

	@media (max-width: 70rem) {
		display: none;
	}
}

.account-content-container {
	flex: 1;
	padding-bottom: 4rem;
	min-width: 0;
}

.form-heading,
.account-heading {
	padding: 4rem 10%;
	font-size: 1.75rem;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1rem;
	line-height: 1.3;
	margin-bottom: 2rem;

	&:not(:first-of-type) {
		margin-top: 10rem;
		border-top: 2px solid var(--accent-scheme);
	}
}

.form form,
.account form,
.account-table-container {
	padding: 0 10%;
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
}

form label {
	display: block;
	font-size: 1.2rem;
	font-weight: 700;
	margin-bottom: 0.75rem;
}

form input {
	display: block;
	font-family: inherit;
	font-size: 1.1rem;
	color: inherit;
	padding: 1.25rem 1.75rem;
	border: none;
	width: 100%;
	background-color: var(--background-deemphasized);
	border-top: 3px solid transparent;
	border-bottom: 3px solid transparent;
	transition: all 0.3s;
	border-radius: 0.5rem;

	&:focus {
		outline: none;
		border-bottom: 3px solid #55c57a;

		&:invalid {
			border-bottom: 3px solid #ff7730;
		}
	}

	&::-webkit-input-placeholder {
		color: #bbb;
	}
}

.photo-upload {
	display: flex;
	align-items: center;
	font-size: 1.2rem;
}

.photo-upload img {
	height: 7.5rem;
	width: 7.5rem;
	border-radius: 50%;
	margin-right: 2rem;
	object-fit: cover;
}

.photo-upload input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

	&:focus + label {
		outline: 3px solid var(--brandcolor);
		outline-offset: 3px;
	}
}

.photo-upload label {
	color: var(--brandcolor);
	text-decoration: none;
	border-bottom: 1px solid var(--brandcolor);
	padding: 3px;
	transition: all 0.2s;
	cursor: pointer;

	&:hover {
		background-color: var(--brandcolor);
		color: var(--background-highlight);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
		transform: translateY(-2px);
		border-radius: 0.25rem;
	}
}

form .submit-group {
	display: flex;
	align-items: center;
	justify-content: end;
}

form .signup-button,
form .forgot-password-button {
	color: var(--brandcolor);
	text-decoration: none;
	border-bottom: 1px solid var(--brandcolor);
	padding: 3px;
	transition: all 0.2s;
	cursor: pointer;
	margin-right: auto;
	display: inline-block;

	&:hover {
		background-color: var(--brandcolor);
		color: var(--background-highlight);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
		transform: translateY(-2px);
		border-radius: 0.25rem;
	}
}

form .forgot-password-button {
	margin-top: 0.5rem;
	display: none;
}

.form-button {
	display: block;
	font-size: 1.2rem;
	padding: 1.4rem 3rem;
	border-radius: 10rem;
	text-transform: uppercase;
	text-decoration: none;
	position: relative;
	transition: all 0.4s;
	font-weight: 400;
	border: none;
	cursor: pointer;
	background-color: var(--brandcolor);
	color: #fff;

	&:hover {
		transform: scale(1.05);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
	}

	&:active {
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	}

	&:focus {
		background-color: var(--accent-dark);
	}
}

.account .form-button {
	padding: 0.6rem 1.5rem;
	font-size: 1rem;
}

.booking-card {
	display: grid;
	grid-template-columns: 3fr 4fr;
	grid-template-rows: auto 1fr 1fr 0rem;
	height: 10rem;
	overflow: hidden;
	border-radius: 1rem;
	background-color: var(--background-deemphasized);
	gap: 0.5rem;

	@media (max-width: 45rem) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 35rem) {
		grid-template-columns: unset;
		height: unset;
	}
}

.booking-title {
	color: #ffffff;
	font-weight: 300;
	font-size: 1.5rem;
	text-align: center;
	z-index: 10;
	grid-column: 1/-1;
	grid-row: 1/2;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0.5rem;

	@media (max-width: 35rem) {
		display: block;
		text-align: unset;
		border-bottom: 0.1rem solid var(--accent-scheme);
		margin: 0.25rem;
	}
}

.booking-title span {
	padding: 0.25rem 0.6rem;
	line-height: 1.4;
	box-decoration-break: clone;
	background-image: linear-gradient(to right bottom, #91c8e384, var(--brandcolor));
	border-radius: 0.5rem;

	@media (max-width: 35rem) {
		background-image: unset;
	}
}

.booking-picture-container {
	grid-row: 1/-1;
	grid-column: 1/2;
	height: 100%;
	width: 100%;
	max-height: 10rem;
	clip-path: circle(farthest-side at 0% 30%);

	@media (max-width: 35rem) {
		display: none;
	}
}

.booking-picture-container img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.booking-data {
	min-height: 0;
	font-size: 1rem;
	display: flex;
	align-items: center;
	padding: 0.5rem;
}

.booking-data svg {
	margin-right: 0.5rem;
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--brandcolor);
}
